<template>

<com-content class="badges" :inner="true">

	<com-head title="How many attendees are expected?" text="Fees are split into six levels and reduce the more attendees you have." icon="" />

	<div class="badges-wrapper">

		<div v-for="(level, index) in levels" :key="index" v-tooltip="'Click to select'" class="badges-item" :class="{'is-active': index + 1 === value}" v-on:click="onLevelClick(index + 1)">{{ level }}</div>

	</div>

</com-content>

</template>

<script>

export default {

	props: ['value'],
	
	components: {

		'com-head': () => import('./Head'),
		'com-content': () => import('./../common/Content')

	},

	data: function() {

		return {
			levels: [
				'1 - 1000', 
				'1001 - 2000', 
				'2001 - 4000', 
				'4001 - 8000', 
				'8001 - 12000', 
				'12001 - 16000'
			]
		}

	},

	methods: {

		onLevelClick: function(value) {

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.badges {
	padding: 50px 0px 40px 0px;
	z-index: 2;
}

.is-mobile .badges {
	padding: 40px 10px 20px 10px;
}

.badges:after {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 24px 32px 0 32px;
	border-color: #fff transparent transparent transparent;
	position: absolute;
	bottom: -24px;
	left: 50%;
	margin-left: -32px;
}

.badges-wrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
}

.is-mobile .badges-wrapper {
	grid-template-columns: 1fr 1fr;
}

.badges-item {
	height: 40px;
	background-color: #1e3054;
	border-radius: 8px;
	line-height: 40px;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	cursor: pointer;
	transition: transform 100ms linear;
}

.is-desktop .badges-item:hover {
	transform: scale(1.05);
}

.badges-item.is-active {
	background-color: #ffbe7a;
	color: #fff;
}

</style>